import pdfMake from "@sirfull/pdfmake/build/pdfmake";
// import pdfMakeB from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import { toPng } from 'html-to-image';
import { format, parseISO } from 'date-fns'

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Manrope: {
    normal: 'https://configuratore.mad051.it/fonts/manrope/manrope-regular.otf',
    bold: 'https://configuratore.mad051.it/fonts/manrope/manrope-bold.otf',
    bolditalics: 'https://configuratore.mad051.it/fonts/manrope/manrope-extrabold.otf'
  }
}


pdfMake.tableLayouts = {
  prodsLayout: {
    hLineWidth: function (i, node) {
      return 1
    },
    vLineWidth: function (i) {
      return 0
    },
    hLineColor: function (i) {
      return '#aaa'
    },
    paddingLeft: function (i) {
      return 8
    },
    paddingRight: function (i, node) {
      return 8
    },
    paddingBottom: function (i) {
      return 5
    },
    paddingTop: function (i, node) {
      return 5
    }
  },
  recapLayout: {
    hLineWidth: function (i, node) {
      return 1
    },
    vLineWidth: function (i) {
      if (i != 1) return 0
      return 1
    },
    hLineColor: function (i) {
      return '#aaa'
    },
    vLineColor: function (i) {
      return '#aaa'
    },
    paddingLeft: function (i) {
      return 20
    },
    paddingRight: function (i, node) {
      return 20
    },
    paddingBottom: function (i) {
      return 12
    },
    paddingTop: function (i, node) {
      return 12
    },
    fillColor: function (i, node) {
      return '#ededed'
    }
  }
}

const moodDocument = (data) => {
  const companyName = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.name || '') : ''
  const companyAddress = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.address || '') : ''
  const companyPhone = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.phone || '') : ''
  const companyEmail = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.email || '') : ''
  return {
    defaultStyle: {
      font: 'Manrope',
      fontSize: 13
    },
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [ 40, 0, 40, 61 ],
    images: {
      logoRistrutturo: 'https://configuratore.mad051.it/images/irea_black.png',
      logoMAD: 'https://configuratore.mad051.it/mad051-logo-dark.png',
      company: data.company
    },
    
    footer: {
      layout: 'noBorders',
      table: {
        widths: [280, 280, 280],
        body: [
          [
            {
              margin: [ 20, 0, 0, 0],
              stack: [
                companyName,
                companyAddress
              ],
              fontSize: 8
            },
            {
              stack: [
                `Telefono ${companyPhone}`,
                `Email ${companyEmail}`
              ],
              fontSize: 8
            },
            {
              layout: 'noBorders',
              table: {
                widths: [60, 60],
                body: [
                  [
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'MEMBRO', fontSize: 6 }],
                          [ { image: 'logoRistrutturo', width: 53, height: 30 } ],
                        ]
                      }
                    },
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'POWERED BY', fontSize: 6 }],
                          [ { image: 'logoMAD', width: 30, height: 30 } ],
                        ]
                      }
                    },
                  ],
                ]
              }
            }
          ]
        ]
      }
    },

    // footer : function (currentPage, pageCount) {
    //   return {
    //     table: {
    //       body: [
    //         {
    //           stack:[
    //             {
    //               canvas: [
    //                 {
    //                   type: 'line',
    //                   x1: 0,
    //                   y1: 5,
    //                   x2: 800,
    //                   y2: 5,
    //                   lineWidth: 0.5
    //                 }
    //               ]
    //             },
    //             {
    //               columns: [
    //                 {
    //                   stack: [
    //                     { text: '', margin: 10 },
    //                     companyName,
    //                     companyAddress
    //                   ],
    //                   fontSize: 8
    //                 },
    //                 {
    //                   stack: [
    //                     { text: '', margin: 10 },
    //                     companyPhone ? `Telefono ${companyPhone}` : '',
    //                     companyEmail ? `Email ${companyEmail}` : ''
    //                   ],
    //                   fontSize: 8
    //                 },
    //                 { text:'', width: 300 },
    //                 {
    //                   layout: 'noBorders',
    //                   table: {
    //                     widths: [60, 60],
    //                     body: [
    //                       [
    //                         { 
    //                           align: 'right',
    //                           layout: 'noBorders',
    //                           table: {
    //                             widths: [60],
    //                             body: [
    //                               [{ text: 'MEMBRO', fontSize: 6 }],
    //                               [ { image: 'logoRistrutturo', width: 53, height: 30 } ],
    //                             ]
    //                           }
    //                         },
    //                         { 
    //                           align: 'right',
    //                           layout: 'noBorders',
    //                           table: {
    //                             widths: [60],
    //                             body: [
    //                               [{ text: 'POWERED BY', fontSize: 6 }],
    //                               [ { image: 'logoMAD', width: 30, height: 30 } ],
    //                             ]
    //                           }
    //                         },
    //                       ],
    //                     ]
    //                   }
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //       ],
    //     },
    //     margin: [20, 0],
    //     alignment : 'left'
    //   };
    // },
    
    content: [
      {
        margin: [0,10],
        layout: 'noBorders',
        table: {
          widths: [150, '*'],
          body: [
            [
              {
                image: 'company',
                fit: [80, 80]
              },
              {
                stack: [
                  { text: 'MOODBOARD', bold: true, fontSize: 20},
                  { text: data.project?.title ? `${data.project.title} - ${data.room?.name ? data.room.name : ''}` : `${data.room?.name || ''}` , bold: true, fontSize: 9, margin: [0, 0, 0, 5]},
                  { text: data.created ? 'Data: ' + format(parseISO(data.created), 'dd/MM/yyyy') : '', fontSize: 9},
                  { 
                    text: data.userData.username ? `Creato da: ${data.userData.username} ` : '',
                    fontSize: 9
                  }
                ]
              }
            ]
          ]
        }
      },
      { absolutePosition: { x: 200, y: 150 }, image: data.img , margin: [50, 0, 0, 0], width: 500}
    ]
  }
}

const getAttributes = (lineItem, product) => {
  return product?.attributes?.edges?.length ? product.attributes.edges.map(({ node }) => {
    let relatedAttribute
    let relatedTerm = null
    if (!node.terms || !node.options) {
      if (
        lineItem.product.attributes?.edges?.length &&
        lineItem.product.attributes.edges.some(({ node: attribute }) => {
          return attribute.terms?.nodes?.length && node.value
          ? attribute.terms.nodes.some(term => term.slug === node.value)
          : false
        })
        ) {
          lineItem.product.attributes.edges.map(({ node: attribute }) => {
            attribute.terms.nodes.map(term => {
            relatedTerm =
              term.slug === node.value
                ? term?.name ? term.name : node.value
                : relatedTerm
            return term
          })
          return attribute
        })
      }
    }

    if (node.terms?.nodes?.length) {
      relatedAttribute = node.terms.nodes[0].name
    } else if(node.options?.length) {
      relatedAttribute = node.options[0].toUpperCase() 
    } else {
      relatedAttribute = relatedTerm || node.value
    }
    return relatedAttribute
  }).join(' | ') : ''
}

const quoteDocument = (data) => {
  const companyName = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.name || '') : ''
  const companyAddress = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.address || '') : ''
  const companyPhone = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.phone || '') : ''
  const companyEmail = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.email || '') : ''
  const tabLength = data.quote?.lineItems?.length

  let totalTax = 0

  if (data.quote.lineItems.length) { 
    totalTax += data.quote.lineItems.map(x => x.taxes).filter(_ => _).reduce((partialSum, a) => partialSum + a, 0)
  }
  
  totalTax = totalTax.toFixed(2)
  return {
    pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
    },
    defaultStyle: {
      font: 'Manrope', 
      fontSize: 13
    },
    pageSize: 'A4',
    pageMargins: [ 40, 40, 40, 60 ],
    images: {
      logoRistrutturo: 'https://configuratore.mad051.it/images/irea_black.png',
      logoMAD: 'https://configuratore.mad051.it/mad051-logo-dark.png',
      company: data.company
    },
    footer: {
      layout: 'noBorders',
      table: {
        widths: [200, 200, 200],
        body: [
          [
            {
              margin: [ 20, 0, 0, 0],
              stack: [
                companyName,
                companyAddress
              ],
              fontSize: 8
            },
            {
              stack: [
                `Telefono ${companyPhone}`,
                `Email ${companyEmail}`
              ],
              fontSize: 8
            },
            {
              layout: 'noBorders',
              table: {
                widths: [60, 60],
                body: [
                  [
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'MEMBRO', fontSize: 6 }],
                          [ { image: 'logoRistrutturo', width: 53, height: 30 } ],
                        ]
                      }
                    },
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'POWERED BY', fontSize: 6 }],
                          [ { image: 'logoMAD', width: 30, height: 30 } ],
                        ]
                      }
                    },
                  ],
                ]
              }
            }
          ]
        ]
      }
    },
    
    content: [
      {
        margin: [0,0,0,30],
        layout: 'noBorders',
        table: {
          widths: [150, '*'],
          body: [
            [
              {
                image: 'company',
                fit: [100, 100]
              },
              {
                margin: [ 150, 0, 0, 0 ],
                stack: [
                  { text: 'PREVENTIVO', bold: true, fontSize: 20 },
                  { text: data?.project?.title ? data.project.title : '', bold: true, fontSize: 9, margin: [0, 0, 0, 5] },
                  { text: data.quote?.modified ? 'Data: ' + format(parseISO(data.quote?.modified), 'dd/MM/yyyy') : '', fontSize: 9 },
                  { 
                    text: data.userData.firstName || data.userData.lastName
                    ? `Creato da: ${(data.userData.firstName || '')} ${(data.userData.lastName || '')}`
                    : '',
                    fontSize: 9
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: 'prodsLayout',
        table: {
          widths: ['*', 30, 50, 80, 30],
          headerRows: 1,
          body: [
            [
              { text: 'Descrizione', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '70%' },
              { text: 'UM', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '10%' },
              { text: 'Quantità', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '5%', alignment: 'right' },
              { text: 'Importo', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '10%', alignment: 'right' },
              { text: 'IVA', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '5%', alignment: 'right' }
            ],
            ...data.mats.map(prod => {
              let attributes = ''
              const product = prod.variation?.node || prod.variation || prod.product

              attributes = getAttributes(prod, product)
              return [
                { 
                  stack: [
                    prod.product.name,
                    { text: attributes }
                  ],
                  fontSize: 9,
                  margin: [0, 5],
                  width: '70%'
                },
                { text: prod.product?.metaData?.um ? prod.product.metaData.um.toLowerCase().replaceAll('m2', 'mq') : '', fontSize: 9, margin: [0, 5], width: '10%'},
                // { text: prod.product?.metaData?.mq ? prod.quantity * prod.product.metaData.mq : prod.quantity, fontSize: 9, margin: [0, 5], width: '5%'},
                { text: prod.product?.metaData?.mq ? (prod.quantity * prod.product.metaData.mq).toFixed(2) : prod.quantity, fontSize: 9, margin: [0, 5], width: '5%', alignment: 'right'},
                { text: prod.total && typeof prod.total === 'string' ? prod.total : prod.total.toFixed(2), fontSize: 9, margin: [0, 5], width: '10%', alignment: 'right' },
                { text: prod.metaData.vat.value, fontSize: 9, margin: [0, 5], width: '5%', alignment: 'right' }
              ]
            }),
            ...data.prods.map(prod => {
              // const attributes = prod.product.attributes.edges.map( edge => ({ text: edge.node.options[0].toUpperCase() }))
              let attributes = ''
              const product = prod.variation?.node || prod.variation || prod.product
              attributes = getAttributes(prod, product)
              return [
                { 
                  stack: [
                    prod.product.name,
                    { text: attributes }
                  ],
                  fontSize: 9
                },
                { text: prod.product?.metaData?.um ? prod.product.metaData.um.toLowerCase().replaceAll('m2', 'mq') : '', fontSize: 9 },
                { text: prod.quantity, fontSize: 9, alignment: 'right' },
                { text: prod.total && typeof prod.total === 'string' ? prod.total : prod.total.toFixed(2), fontSize: 9, alignment: 'right' },
                { text: prod.metaData.vat.value, fontSize: 9, alignment: 'right' }
              ]
            })
          ]
        }
      },
      {
        margin: [ 0, tabLength <= 8 ? 0 : 50],
        absolutePosition: tabLength <= 8 ? { x: 40, y: 600 } : false,
        pageBreak: tabLength <= 8 ? '' : 'before',
        layout: 'recapLayout',
        table: {
          widths: ['60%', '40%'],
          body: [
            [
              {
                layout: 'recapLayout',
                stack: [
                  /* furnishings section  begin */
                    data.furnishings.length ? { columns: [ 
                      { text: 'Arredi', bold: true, fontSize: 9, width: '60%' }, 
                      { text: 'Importo', bold: true, fontSize: 9, width: '20%' }, 
                      { text: 'IVA', bold: true, fontSize: 9, width: '20%' }
                    ]} : [],
                    ...data.furnishings.map(furnish => {
                      const furnishPrice = furnish.total && typeof furnish.total !== 'string' ? furnish.total.toFixed(2) : '0.00'
                      return [
                        { 
                          columns: [ {
                            text: furnish.metaData?.furnishName?.value ? furnish.metaData.furnishName.value : furnish.product.name,
                            fontSize: 9,
                            margin: [0, 2],
                            width: '60%'
                          },
                          { text: `€ ${furnishPrice}`, fontSize: 9, margin: [0, 2], width: '20%'},
                          { text: furnish.metaData.vat.value, fontSize: 9, margin: [0, 2], width: '20%'},
                        ],
                      },
                    ]
                  }),
                  /* furnishings section  end */
                  /* service section  begin */
                  data.services.length ? { columns: [ 
                    { text: 'Servizi / Maggiorazioni', bold: true, fontSize: 9, width: '60%' }, 
                    { text: 'Importo', bold: true, fontSize: 9, width: '20%' }, 
                    { text: 'IVA', bold: true, fontSize: 9, width: '20%' }
                  ]} : [],
                  ...data.services.map(service => {
                    const servicePrice = service.total && typeof service.total !== 'string' ? service.total.toFixed(2) : '0.00'
                    return [
                      { 
                        columns: [ {
                          text: service.metaData?.serviceName?.value ? service.metaData.serviceName.value : service.product.name,
                          fontSize: 9,
                          margin: [0, 2],
                          width: '60%'
                        },
                        { text: `€ ${servicePrice}`, fontSize: 9, margin: [0, 2], width: '20%'},
                        { text: service.metaData.vat.value, fontSize: 9, margin: [0, 2], width: '20%'},
                      ],
                    },
                  ]
                })
                /* service section  end */
              ],
              fillColor: '#fff'
            },
            [
              {
                margin: [ 0 , 10 ],
                stack: [
                  {
                    margin: [ 50, 0, 0, 0],
                    columns: [
                      { text: 'Totale', fontSize: 11 }, { text: data.subtotal ? '€' + data.subtotal : 0, fontSize: 11, alignment: 'right' }
                    ],
                  },
                  {
                    margin: [ 50, 0, 0, 0],
                    columns: [
                      { text: 'Totale IVA', fontSize: 11 }, { text: data.taxes ? '€' + data.taxes : 0, fontSize: 11, alignment: 'right' }
                    ],
                  },
                  {
                    text: data.total ? '€' + data.total : 0,
                    fontSize: 20,
                    bold: true,
                    alignment: 'right',
                    margin: [ 0, 20, 0, 0]
                  }
                ]
              }
            ]
          ]
        ]
      }
    }
  ]
}
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: { pdfMake, toPng, moodDocument, quoteDocument }
  }
})